import styled from 'styled-components';

export const FloatingComponent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(160deg, #fbf9f1, #fefefe);
  justify-content: center;
  align-items: center;
  gap: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 340px;
  overflow: hidden;
`;

const BaseEllipse = styled.div`
  position: absolute;
  box-sizing: border-box;
  border-radius: 50%;
  z-index: -10;
`;

export const Ellipse1 = styled(BaseEllipse)`
  width: 361px;
  height: 361px;
  left: -176px;
  top: 164px;
  background: transparent;
  border: 62px solid rgba(254, 226, 214, 0.47);
  box-shadow: 8px -8px 10px rgba(254, 241, 235, 0.25);
`;

export const Ellipse2 = styled(BaseEllipse)`
  width: 190px;
  height: 190px;
  left: 157px;
  top: -137px;
  background: #fbf9f1;
  border: 26px solid #f0e5f2;
  box-shadow: 0px 4px 4px rgba(240, 229, 242, 0.5);
`;

export const Ellipse4 = styled(BaseEllipse)`
  width: 429px;
  height: 429px;
  left: 573px;
  top: -208px;
  border: 4px solid rgba(170, 213, 220, 0.3);
`;

export const Ellipse3 = styled(BaseEllipse)`
  width: 414px;
  height: 414px;
  left: 589px;
  top: -206px;
  background: #fafdfc;
  border: 75px solid rgba(238, 247, 248, 0.78);
  box-shadow: -4px 4px 4px 20px rgba(241, 248, 249, 0.5);
`;
