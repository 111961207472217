import PropTypes from 'prop-types';
import { Button } from 'components/common';
import { notification } from 'antd';

export const downloadGuidRegex =
  'download=(?:\\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\\}{0,1})';
const extractContent = html => {
  let htmlToRender = html;
  let guid;
  const downloadGuidMatch = html.match(downloadGuidRegex);
  if (downloadGuidMatch) {
    const downloadGuid = downloadGuidMatch[0];
    guid = downloadGuid.replace('download=', '');
    htmlToRender = htmlToRender.replace(/<a href=.+/i, '');
  }

  return [htmlToRender, guid];
};

const DownloadNotification = ({ html, replace, notificationKey }) => {
  const [htmlToRender, guid] = extractContent(html);
  const handleDownload = () => {
    const params = new URLSearchParams({ download: guid });
    replace({ pathname: window.location.pathname, search: params.toString() });
    notification.close(notificationKey);
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlToRender }} />
      {guid && (
        <>
          <Button type='link' onClick={handleDownload} style={{ padding: 0 }}>
            Click here
          </Button>{' '}
          to download the report now.
        </>
      )}
    </div>
  );
};

DownloadNotification.propTypes = {
  html: PropTypes.string.isRequired,
  replace: PropTypes.func.isRequired,
};

export default DownloadNotification;
