import * as FromAlerts from 'reducers/alerts/selectors';

export const SLICE = 'alerts';

export const getAlertsView = state => FromAlerts.getAlertsView(state[SLICE]);
export const getAlertsFilter = state =>
  FromAlerts.getAlertsFilter(state[SLICE]);
export const getResearcherFilter = state =>
  FromAlerts.getResearcherFilter(state[SLICE]);
export const getOrganisationFilter = state =>
  FromAlerts.getOrganisationFilter(state[SLICE]);
export const getResearcherToUnfollow = state =>
  FromAlerts.getResearcherToUnfollow(state[SLICE]);
export const getOrganisationToUnfollow = state =>
  FromAlerts.getOrganisationToUnfollow(state[SLICE]);
export const getAlertToDelete = state =>
  FromAlerts.getAlertToDelete(state[SLICE]);
export const getAlertToEdit = state => FromAlerts.getAlertToEdit(state[SLICE]);
export const getAlertToCreate = state =>
  FromAlerts.getAlertToCreate(state[SLICE]);
export const getSelectedResearchers = state =>
  FromAlerts.getSelectedResearchers(state[SLICE]);
export const getResearchersToUnfollow = state =>
  FromAlerts.getResearchersToUnfollow(state[SLICE]);
export const getSelectedOrganisations = state =>
  FromAlerts.getSelectedOrganisations(state[SLICE]);
export const getOrganisationsToUnfollow = state =>
  FromAlerts.getOrganisationsToUnfollow(state[SLICE]);
