export const getAlertsView = state => state.view;
export const getResearcherFilter = state => state.researcherFilter;
export const getOrganisationFilter = state => state.organisationFilter;
export const getResearcherToUnfollow = state => state.researcherToUnfollow;
export const getOrganisationToUnfollow = state => state.organisationToUnfollow;
export const getAlertToDelete = state => state.alertToDelete;
export const getAlertToEdit = state => state.alertToEdit;
export const getAlertsFilter = state => state.alertsFilter;
export const getAlertToCreate = state => state.alertToCreate;
export const getSelectedResearchers = state => state.selectedResearchers;
export const getResearchersToUnfollow = state => state.researchersToUnfollow;
export const getSelectedOrganisations = state => state.selectedOrganisations;
export const getOrganisationsToUnfollow = state =>
  state.organisationsToUnfollow;
