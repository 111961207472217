import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as Pages from 'components';
import {
  Logo,
  AuthedRoute,
  Route,
  Breadcrumb,
  ErrorBoundary,
  DemoMode,
} from 'components/common';
import BaseLayout from 'components/Login/BaseLayout';
import { AuthProvider, DarkModeProvider } from 'hooks';

import store from './store';
import { Container, Body } from './styled';
import packageInfo from '../package.json';
import { NavigationProvider } from 'hooks/useNavigation';

function App() {
  return (
    <Router>
      <NavigationProvider>
        <DarkModeProvider>
          <Pages.ThemeWrapper>
            <Pages.ExternalRedirect />
            <Pages.LegacyRedirects />
            <Provider store={store}>
              <AuthProvider>
                <Pages.FreshChatWrapper>
                  <Pages.NotificationWrapper>
                    <Pages.RestoreState />
                    <Switch>
                      <Route path='/login' title='Login'>
                        <BaseLayout>
                          <Pages.Login />
                        </BaseLayout>
                      </Route>
                      <Route path='/reset' title='Reset Password'>
                        <BaseLayout>
                          <Pages.ResetPassword />
                        </BaseLayout>
                      </Route>
                      <Route path='/setpassword' title='Set Password'>
                        <BaseLayout>
                          <Pages.SetPassword />
                        </BaseLayout>
                      </Route>
                      <Pages.Captcha>
                        <AuthedRoute path='/'>
                          <Pages.ProductFruitWrapper>
                            <Pages.HotjarWrapper>
                              <Pages.DownloadRedirect />
                              <Container>
                                <Pages.Navigation />
                                <Body>
                                  <Container>
                                    <Logo />
                                    <DemoMode />
                                  </Container>
                                  <Breadcrumb />
                                  <ErrorBoundary version={packageInfo.version}>
                                    <Switch>
                                      <Route exact path='/' title='Search'>
                                        <Pages.Search />
                                      </Route>
                                      <Route
                                        path='/searches'
                                        title='Searches & Alerts'
                                      >
                                        <Pages.Alerts />
                                      </Route>
                                      <Route path='/lists/:id?' title='Lists'>
                                        <Pages.Lists />
                                      </Route>
                                      <Route path='/calendar' title='Calendar'>
                                        <Pages.Calendar />
                                      </Route>
                                      <Route
                                        path='/purchasing'
                                        title='Purchasing Search'
                                      >
                                        <Pages.Purchasing />
                                      </Route>
                                      <Route path='/settings' title='Settings'>
                                        <Pages.Settings />
                                      </Route>
                                      <Route
                                        path='/researcher/:id'
                                        title='Researcher'
                                      >
                                        <Pages.Researcher />
                                      </Route>
                                      <Route
                                        path='/organisation/:id'
                                        title='Organisation'
                                      >
                                        <Pages.Organisation />
                                      </Route>
                                      <Route
                                        path='/project/:id'
                                        title='Project'
                                      >
                                        <Pages.Project />
                                      </Route>

                                      <Route path='/poster/:id' title='Poster'>
                                        <Pages.Poster />
                                      </Route>
                                      <Route
                                        path='/publication/:id'
                                        title='Publication'
                                      >
                                        <Pages.Publication />
                                      </Route>
                                      <Route
                                        path='/trial/:id'
                                        title='Clinical Trial'
                                      >
                                        <Pages.Trial />
                                      </Route>
                                      <Route
                                        path='/item/:id'
                                        title='Purchased Item'
                                      >
                                        <Pages.Item />
                                      </Route>
                                      <Route>
                                        <Pages.NotFoundRedirect />
                                      </Route>
                                    </Switch>
                                  </ErrorBoundary>
                                </Body>
                              </Container>
                            </Pages.HotjarWrapper>
                          </Pages.ProductFruitWrapper>
                        </AuthedRoute>
                      </Pages.Captcha>
                    </Switch>
                  </Pages.NotificationWrapper>
                </Pages.FreshChatWrapper>
              </AuthProvider>
            </Provider>
          </Pages.ThemeWrapper>
        </DarkModeProvider>
      </NavigationProvider>
    </Router>
  );
}

export default App;
