import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { usePost } from 'hooks';

import { unfollowOrganisation, unfollowResearcher } from 'services/api';

function useUnfollow(onUnfollowSuccess) {
  const dispatch = useDispatch();
  const [{ res: success, loading, error }, postUnfollow] = usePost({
    url: unfollowResearcher,
  });

  const [
    {
      res: successOrganisationUnfollow,
      loadingOrganisation,
      errorOrganisation,
    },
    postUnfollowOrganisation,
  ] = usePost({
    url: unfollowOrganisation,
  });

  useEffect(() => {
    if (success || error?.status === 410) {
      onUnfollowSuccess();
    }
  }, [success, onUnfollowSuccess, dispatch, error]);

  useEffect(() => {
    if (successOrganisationUnfollow || errorOrganisation?.status === 410) {
      onUnfollowSuccess();
    }
  }, [
    successOrganisationUnfollow,
    onUnfollowSuccess,
    dispatch,
    errorOrganisation,
  ]);

  const handleUnfollowResearcher = researcherId => {
    postUnfollow({ researcherId });
  };

  const handleUnfollowOrganisation = organisationToUnfollow => {
    postUnfollowOrganisation({
      organisationId: organisationToUnfollow.organisationId,
      organisationType: organisationToUnfollow.organisationType,
    });
  };

  return [
    handleUnfollowResearcher,
    loading,
    handleUnfollowOrganisation,
    loadingOrganisation,
  ];
}

export default useUnfollow;
