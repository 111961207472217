import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'components/common';

const getFundingIcon = fundingType =>
  fundingType.toLowerCase() === 'seed'
    ? 'S'
    : fundingType[fundingType.length - 1].toUpperCase();

const Container = styled.div`
  border: 2px solid ${p => p.theme.teal};
  padding: 0 6px;
  line-height: 22px;
  font-weight: 700;
`;

function FundingTypeIcon({ fundingType, style = {} }) {
  if (!fundingType) return null;
  return (
    <Tooltip
      title={`Raised ${fundingType[0].toLowerCase() +
        fundingType.slice(1)} funding`}
    >
      <Container style={style}>{getFundingIcon(fundingType)}</Container>
    </Tooltip>
  );
}

FundingTypeIcon.propTypes = {
  fundingType: PropTypes.string,
};

export default FundingTypeIcon;
