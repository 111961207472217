import { useParams } from 'react-router-dom';

import { Loader, Jumper } from 'components/common';
import { getTrial } from 'services/api';
import { usePost } from 'hooks';

import Details from './Details';
import Description from './Description';
import Keywords from './Keywords';
import Arms from './Arms';
import Outcomes from './Outcomes';
import Eligibility from './Eligibility';
import Locations from './Locations';

import { Pane, Container } from './styled';
import CTA from './CTA';

let jumpItems = [
  { to: 'details', label: 'Trial Details' },
  { to: 'related', label: 'Related Information' },
  { to: 'description', label: 'Study Description' },
  { to: 'design', label: 'Study Design' },
  { to: 'keywords', label: 'Keywords' },
  { to: 'mesh', label: 'Focused Research Areas' },
  { to: 'arms', label: 'Arms and Interventions' },
  { to: 'primary', label: 'Primary Outcome Measures' },
  { to: 'secondary', label: 'Secondary Outcome Measures' },
  { to: 'other', label: 'Other Outcome Measures' },
  { to: 'eligibility', label: 'Eligibility Criteria' },
  { to: 'locations', label: 'Contacts and Locations' },
];

const Trial = () => {
  const { id } = useParams();
  const [{ res: trial, loading }] = usePost({
    url: getTrial,
    onMount: true,
    body: { nctId: id },
  });

  if (loading)
    return (
      <Pane>
        <Loader />
      </Pane>
    );
  if (trial.showCta) {
    return <CTA />;
  }

  const {
    study,
    researcherProfiles,
    relatedPublications,
    relatedGrants,
    relatedPublicationsCount,
    relatedGrantsCount,
    studyKeywordsCount,
    studyMeshTermsTotal,
  } = trial;

  if (study.primaryOutcomeMeasurements.length === 0) {
    jumpItems = jumpItems.filter(i => i.to !== 'primary');
  }
  if (study.secondaryOutcomeMeasurements.length === 0) {
    jumpItems = jumpItems.filter(i => i.to !== 'secondary');
  }
  if (study.otherOutcomeMeasurements.length === 0) {
    jumpItems = jumpItems.filter(i => i.to !== 'other');
  }

  return (
    <Container>
      <Jumper items={jumpItems} />
      <Details
        study={study}
        researchers={researcherProfiles}
        relatedPublications={relatedPublications}
        relatedGrants={relatedGrants}
        relatedPublicationsCount={relatedPublicationsCount}
        relatedGrantsCount={relatedGrantsCount}
        studyKeywordsCount={studyKeywordsCount}
        studyMeshTermsTotal={studyMeshTermsTotal}
      />
      <Description study={study} />
      <Keywords study={study} />
      <Arms study={study} />
      <Outcomes study={study} />
      <Eligibility study={study} />
      <Locations study={study} />
    </Container>
  );
};

export default Trial;
