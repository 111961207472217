import { useEffect, useRef } from 'react';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import { useGet } from 'hooks';
import { downloadExport } from 'services/api';
import { FullPageLoader } from 'components/common';

const DownloadRedirect = () => {
  const { search, pathname } = useLocation();
  const { replace } = useHistory();
  const { download: downloadKey } = qs.parse(search);
  const [{ res, loading }, getDownload] = useGet({
    url: downloadExport(downloadKey),
    responseType: 'blob',
  });
  const downloaded = useRef(false);

  useEffect(() => {
    if (downloadKey && pathname !== '/notfound') {
      downloaded.current = false;
      getDownload();
    }
  }, [downloadKey, getDownload, pathname]);

  useEffect(() => {
    if (res && !downloaded.current) {
      const fileName = res.req.getResponseHeader('file-name');
      const contentType = res.req.getResponseHeader('content-type');
      const blob = new Blob([res.data], { type: contentType });
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      replace(pathname);
      downloaded.current = true;
    }
  }, [res, pathname, replace]);

  if (loading) {
    return <FullPageLoader />;
  }
  return null;
};

export default DownloadRedirect;
