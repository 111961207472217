import { Skeleton } from 'antd';
import { Container, Pane, Row } from './styled';
import { UpgradeLicense } from 'components/common';

function CTA() {
  return (
    <>
      <Container>
        <Row>
          <Pane title='Item Details'>
            <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
          </Pane>
        </Row>
        <Row>
          <Pane title='Purchaser Details'>
            <Skeleton paragraph={{ rows: 2 }} style={{ marginTop: 5 }} />
          </Pane>
          <Pane title='Buyer Details'>
            <Skeleton paragraph={{ rows: 2 }} style={{ marginTop: 5 }} />
          </Pane>
          <Pane title='Delivery Details'>
            <Skeleton paragraph={{ rows: 2 }} style={{ marginTop: 5 }} />
          </Pane>
          <Pane title='Supplier Details'>
            <Skeleton paragraph={{ rows: 2 }} style={{ marginTop: 5 }} />
          </Pane>
        </Row>
      </Container>
      <UpgradeLicense />
    </>
  );
}

export default CTA;
