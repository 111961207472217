import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'components/common';
import { Divider } from 'antd';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left-width: 0;
  border-right-width: 0;
  padding: 10px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  .selected {
    font-weight: 500;
  }
  .select-all {
    color: ${p => p.theme.linkBlue};
  }
  .unfollow {
    color: ${p => p.theme.orange};
  }
  .cancel {
    color: ${p => p.theme.black};
  }
`;

function SelectedPanel({
  selectedCount,
  allSelected,
  selectAll,
  unselectAll,
  unfollow,
  style = {},
}) {
  if (!selectedCount) {
    return null;
  }

  return (
    <Container style={style}>
      <Content>
        <div className='selected'>{selectedCount} items selected</div>
        {!allSelected && (
          <Button type='link' onClick={selectAll} className='select-all'>
            Select all
          </Button>
        )}
      </Content>
      <Content>
        <Button type='link' onClick={unfollow} className='unfollow'>
          Unfollow selected
        </Button>
        <Divider type='vertical' style={{ height: '22px' }} />
        <Button type='link' onClick={unselectAll} className='cancel'>
          Cancel
        </Button>
      </Content>
    </Container>
  );
}

SelectedPanel.propTypes = {
  selectedCount: PropTypes.number.isRequired,
  allSelected: PropTypes.bool.isRequired,
  selectAll: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  unfollow: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

export default SelectedPanel;
