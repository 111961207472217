import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { useAuth } from 'hooks';
import licenseType, { licenseTypeToStringMap } from 'models/licenseType';
import {
  FloatingComponent,
  Ellipse1,
  Ellipse2,
  Ellipse3,
  Ellipse4,
} from './styled';

const { Text } = Typography;

function UpgradeLicense() {
  const history = useHistory();
  const { hasLicense } = useAuth();
  const hasAcademicLicense = hasLicense([licenseType.academic], true);
  const license = hasAcademicLicense
    ? licenseTypeToStringMap[licenseType.industry]
    : licenseTypeToStringMap[licenseType.academic];

  return (
    <FloatingComponent>
      <Ellipse1 />
      <Ellipse2 />
      <Ellipse3 />
      <Ellipse4 />
      <Text
        style={{
          fontSize: '24px',
          color: '#161616',
          fontWeight: '500',
          textAlign: 'center',
        }}
      >
        Upgrade your License to View {license} Content
      </Text>
      <Text
        style={{
          fontSize: '14px',
          color: '#595959',
          maxWidth: '528px',
          textAlign: 'center',
        }}
      >
        Upgrade your license today to access top-level information about{' '}
        {license.toLowerCase()} companies and contacts.
      </Text>
      <div>
        <Text
          style={{
            fontSize: '13px',
            color: '#161616',
            fontWeight: '500',
            textAlign: 'center',
          }}
        >
          To speak to a team member about upgrading your subscription,{' '}
          <a href={'mailto:laura@scileads.com'}>click here</a>.
        </Text>
      </div>
      <Button onClick={() => history.replace('/')}>Go back to home page</Button>
    </FloatingComponent>
  );
}

UpgradeLicense.propTypes = {
  license: PropTypes.string,
};

export default UpgradeLicense;
