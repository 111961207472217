import {
  useEffect,
  useContext,
  createContext,
  useRef,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const value = useGenerateNavigation();
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);

const actions = {
  push: 'push',
  back: 'back',
  forward: 'forward',
};

const useGenerateNavigation = () => {
  const { listen } = useHistory();
  const locationKeys = useRef([]);
  const actionRef = useRef('');

  useEffect(() => {
    return listen((location, action) => {
      if (action === 'PUSH') {
        locationKeys.current = [location.key];
        actionRef.current = actions.push;
        return;
      }

      if (action === 'POP') {
        if (
          locationKeys.current.length > 1 &&
          locationKeys.current[1] === location.key
        ) {
          const [, ...keys] = locationKeys.current;
          locationKeys.current = keys;
          actionRef.current = actions.forward;
        } else {
          locationKeys.current = [location.key, ...locationKeys.current];
          actionRef.current = actions.back;
        }
      }
    });
  }, [listen]);

  const isBackButtonClicked = useCallback(() => {
    return actionRef.current === actions.back;
  }, []);

  return {
    action: actionRef.current,
    isBackButtonClicked,
  };
};
