import styled from 'styled-components';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Tooltip } from 'components/common';
import { Token as CommonToken } from 'components/common/TokenInput';
import {
  setShowBuilder,
  setFilter,
  clearSearchBuilderFields,
  setFilterGroup,
} from 'actions/search';
import { getSearchBuilderFields } from 'selectors/search';

import BasicFields from './Basic';
import AdvancedFields from './Advanced';
import { formatters, checkFieldsForErrors } from './helpers';

const Token = styled(CommonToken)`
  display: inline-flex;
`;

const SearchBuilder = ({
  onSearch,
  showAdvancedFields,
  id,
  filterGroup,
  filterId,
  onClear,
  tokenValue = 'Lung Cancer',
}) => {
  const dispatch = useDispatch();
  const fields = useSelector(getSearchBuilderFields(id));

  const handleSearch = () => {
    const string = Object.keys(formatters).reduce((acc, key) => {
      const formatted = formatters[key] && formatters[key](fields[key]);
      if (!formatted) return acc;
      return acc ? `${acc} AND ${formatted}` : formatted;
    }, '');

    dispatch(
      filterGroup
        ? setFilterGroup(filterGroup, filterId ? filterId : 'term', string)
        : setFilter(filterId ? filterId : 'term', string)
    );

    onSearch(string);
    dispatch(setShowBuilder(false));
  };

  const handleClose = () => {
    dispatch(setShowBuilder(false));
  };

  const handleClear = () => {
    dispatch(clearSearchBuilderFields(id));
    onClear();
  };

  const Footer = () => {
    const hasInvalidFields = checkFieldsForErrors(fields);
    const invalidMessage = (
      <p>
        There are invalid fields active which may return unexpected search
        results.
        <br />
        <br />
        You may want to review the highlighted fields before searching.
      </p>
    );

    return (
      <>
        <Button onClick={handleClear}>Clear</Button>
        <Button onClick={handleClose}>Cancel</Button>
        <Tooltip title={hasInvalidFields && invalidMessage}>
          <Button type='primary' onClick={handleSearch}>
            Search
          </Button>
        </Tooltip>
      </>
    );
  };

  return (
    <Modal
      open
      title='Search Builder'
      onCancel={handleClose}
      width={750}
      footer={<Footer />}
    >
      <div>
        Enter your desired word or phrase in the inputs below. Press 'Enter'
        after each word or phrase; a green highlight will appear to confirm your
        entry e.g. <Token value={tokenValue} />
      </div>
      <BasicFields id={id} />
      {showAdvancedFields && <AdvancedFields id={id} />}
    </Modal>
  );
};

export default SearchBuilder;
