import { Fragment, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Input,
  Button,
  Link,
  ResearcherEmail,
  Checkbox,
} from 'components/common';
import { useHistory } from 'react-router-dom';
import {
  getResearcherFilter,
  getSelectedResearchers,
  getResearchersToUnfollow,
} from 'selectors/alerts';
import {
  selectResearchers,
  setResearcherFilter,
  setResearchersToUnfollow,
} from 'actions/alerts';
import { useAuth } from 'hooks';
import { SelectedPanel } from 'components/common/Table/MultiSelect';
import {
  getAreSomeSelected,
  getAreAllSelected,
  getSelectedCount,
  select,
  isSelected,
} from 'components/common/Table/MultiSelect/helpers';

import { StyledTable, TableActions } from './styled';
import { tablePerPage } from './helpers';
import { setFiltersFromAlert } from 'actions/search';
import { RecentResults } from './TablePopover/RecentResults';
import BulkUnfollowModal from './Modal/BulkUnfollow';
import useUnfollow from './useUnfollow';
import useBulkUnfollow from './useBulkUnfollow';

export const ResearcherAlerts = ({ data, getResearchersToFollow, loading }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const researcherFilter = useSelector(getResearcherFilter);
  const { push } = useHistory();
  const ref = useRef();
  const filteredData = useMemo(() => {
    if (!data) return [];
    if (!researcherFilter) return data;

    return data.filter(k =>
      `${k.name.toLowerCase()}`.includes(researcherFilter.toLowerCase())
    );
  }, [data, researcherFilter]);

  const researchersToUnfollow = useSelector(getResearchersToUnfollow);
  const selected = useSelector(getSelectedResearchers);
  const someSelected = useMemo(
    () => getAreSomeSelected(data?.length ?? 0, selected),
    [data?.length, selected]
  );
  const allSelected = useMemo(
    () => getAreAllSelected(data?.length ?? 0, selected),
    [data?.length, selected]
  );
  const selectedCount = useMemo(
    () => getSelectedCount(data?.length ?? 0, selected),
    [data?.length, selected]
  );

  const handleUnfollowSuccess = useCallback(() => {
    getResearchersToFollow({
      query: { UserId: user.userId, shouldLog: false },
    });
  }, [getResearchersToFollow, user.userId]);

  const [handleUnfollow, unfollowLoading] = useUnfollow(handleUnfollowSuccess);

  const unSelectAll = useCallback(() => {
    dispatch(selectResearchers({ exclude: {}, include: {}, all: false }));
  }, [dispatch]);

  const handleBulkSuccess = useCallback(() => {
    dispatch(setResearchersToUnfollow());
    unSelectAll();
    getResearchersToFollow({
      query: { UserId: user.userId, shouldLog: false },
    });
  }, [dispatch, getResearchersToFollow, unSelectAll, user.userId]);

  const handleBulkCancel = () => {
    dispatch(setResearchersToUnfollow());
    unSelectAll();
  };

  const [handleBulkUnfollow, bulkUnfollowLoading] = useBulkUnfollow(
    handleBulkSuccess
  );

  const selectAll = () => {
    const selectedIds = allSelected
      ? {}
      : filteredData.reduce((acc, curr) => {
          acc[curr.superResearcherId] = true;
          return acc;
        }, {});
    dispatch(
      selectResearchers({ exclude: {}, include: selectedIds, all: false })
    );
  };

  const handleToggleMember = id => {
    dispatch(selectResearchers(select(id, selected)));
  };

  const unfollow = id => {
    if (isSelected(selected, id)) {
      handleToggleMember(id);
    }

    handleUnfollow(id);
  };

  const bulkUnfollow = () => {
    if (selectedCount === 1) {
      handleBulkUnfollow(selected);
      return;
    }

    dispatch(
      setResearchersToUnfollow({
        members: selected,
        count: selectedCount,
      })
    );
  };

  const researcherConfig = {
    headers: [
      {
        label: (
          <Checkbox
            checked={allSelected && !loading}
            indeterminate={someSelected}
            onClick={selectAll}
          />
        ),
        minWidth: 20,
      },
      { label: 'New', key: 'recentItemsResults', minWidth: 150, maxWidth: 150 },
      { label: 'Researcher', key: 'name', minWidth: 150, maxWidth: 300 },
      {
        label: 'Organisation',
        key: 'organisation',
        minWidth: 150,
        maxWidth: 300,
      },
      { label: 'Email', key: 'email', minWidth: 150, maxWidth: 300 },
      { testId: 'actions', minWidth: 100 },
    ],
    createRow: r => (
      <Fragment key={r.superResearcherId}>
        <div>
          <Checkbox
            checked={isSelected(selected, r.superResearcherId)}
            onClick={() => handleToggleMember(r.superResearcherId)}
          />
        </div>

        <RecentResults onClick={last30DaysClicked} alert={r} ref={ref} />

        <div>
          <Link to={`/researcher/${r.superResearcherId}`}>{r.name}</Link>
        </div>
        <div>{r.organisation}</div>
        <div>
          <ResearcherEmail
            email={r.email}
            emailStatusCategory={r.emailVerificationCategory}
            hideIcon
          />
        </div>

        <TableActions className='rightAlign'>
          <Button
            type='primary'
            size='small'
            onClick={() => unfollow(r.superResearcherId)}
          >
            Unfollow
          </Button>
        </TableActions>
      </Fragment>
    ),
  };

  const last30DaysClicked = alert => {
    dispatch(
      setFiltersFromAlert(
        alert.searchOrigin,
        { term: `SciLeadsSuperResearcherId:${alert.superResearcherId}` },
        'one'
      )
    );
    push('/');
  };

  const handleResearcherChange = value => {
    dispatch(setResearcherFilter(value));
  };

  return (
    <div ref={ref}>
      <Input
        placeholder='Filter Researchers'
        style={{ marginRight: 15, marginTop: 15 }}
        onChange={handleResearcherChange}
        value={researcherFilter}
        allowClear
      />
      <SelectedPanel
        selectedCount={selectedCount}
        allSelected={allSelected}
        selectAll={selectAll}
        unselectAll={unSelectAll}
        unfollow={bulkUnfollow}
        style={{ margin: '10px 0' }}
      />
      <StyledTable
        config={researcherConfig}
        pageSizeOptions={tablePerPage}
        sortable
        paginate
        data={filteredData}
        loading={loading || unfollowLoading || bulkUnfollowLoading}
        testId='researcher-table'
      />
      {researchersToUnfollow && (
        <BulkUnfollowModal
          members={researchersToUnfollow.members}
          totalCount={researchersToUnfollow.count}
          onUnfollowSuccess={handleBulkSuccess}
          onUnfollowCancel={handleBulkCancel}
        />
      )}
    </div>
  );
};

export default ResearcherAlerts;
