export const getSelectedCount = (totalCount, selected) => {
  const selectedMembers = selected;

  if (selectedMembers.all) {
    return totalCount - Object.keys(selectedMembers.exclude).length;
  }

  return Object.keys(selectedMembers.include).length;
};

export const getAreAllSelected = (totalCount, selected) => {
  const allMembersCount = totalCount;
  const selectedMembers = selected;

  const areAllSelected =
    selectedMembers.all && Object.keys(selectedMembers.exclude).length === 0;
  const manualAll =
    !selectedMembers.all &&
    Object.keys(selectedMembers.include).length === allMembersCount;

  return (areAllSelected || manualAll) && totalCount > 0;
};

export const getAreSomeSelected = (totalCount, selected) => {
  const allMembersCount = totalCount;
  const selectedMembers = selected;
  const includedeMembersLength = Object.keys(selectedMembers.include).length;
  const excludedMembersLength = Object.keys(selectedMembers.exclude).length;

  const someIncluded =
    includedeMembersLength > 0 && includedeMembersLength < allMembersCount;
  const someExcluded =
    excludedMembersLength > 0 && excludedMembersLength < allMembersCount;

  return someIncluded || someExcluded;
};

export const select = (id, selectedMembers) => {
  const selected = {
    ...selectedMembers,
    exclude: { ...selectedMembers.exclude },
    include: { ...selectedMembers.include },
  };

  if (selected.all) {
    if (selected.exclude[id]) {
      delete selected.exclude[id];
    } else {
      selected.exclude[id] = true;
    }
  } else {
    if (selected.include[id]) {
      delete selected.include[id];
    } else {
      delete selected.exclude[id];
      selected.include[id] = true;
    }
  }
  return selected;
};

export const isSelected = (selected, id) => {
  return !selected.exclude[id] && (selected.include[id] || selected.all);
};
