import { Skeleton } from 'antd';
import { Statistic, UpgradeLicense } from 'components/common';
import {
  Container,
  Content,
  LeftColumn,
  RightColumn,
  StatisticRow,
  Pane,
} from './styled';
import WorldMap from './WorldMap';

function CTA(props) {
  return (
    <>
      <Container style={{ filter: 'blur(2px)' }}>
        <Content>
          <LeftColumn>
            <Pane title='Publication Details'>
              <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
            </Pane>
            <StatisticRow>
              <Statistic title='Authors' icon='author' value='-' />
              <Statistic title='Cited By' icon='citation' value='-' />
              <Statistic title='References' icon='reference' value='-' />
              <Statistic title='Keywords' icon='keywords' value='-' />
            </StatisticRow>
            <Pane title='Abstract'>
              <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
            </Pane>
            <Pane title='Keywords'>
              <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: 5 }} />
            </Pane>
            <Pane title='Full Text'>
              <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: 5 }} />
            </Pane>
            <Pane title='Focused Research Areas'>
              <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: 5 }} />
            </Pane>
            <Pane title='Topics'>
              <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: 5 }} />
            </Pane>
          </LeftColumn>
          <RightColumn>
            <Pane title='Top 5 Keywords'>
              <Skeleton paragraph={{ rows: 10 }} style={{ marginTop: 5 }} />
            </Pane>
            <Pane title='Related Information'>
              <Skeleton paragraph={{ rows: 3 }} style={{ marginTop: 5 }} />
            </Pane>
            <WorldMap data={[]} />
          </RightColumn>
        </Content>
      </Container>
      <UpgradeLicense />
    </>
  );
}

export default CTA;
