import { notification } from 'antd';

export const EwrcaTypes = Object.freeze({
  Ewrca: 'ewrca',
  OrganisationExportEwrca: 'organisationOnlyExportEwrca',
});

export const ewrcaNotification = {
  message: 'Cannot Process',
  description:
    'This export is too large to process, please refine your search and try again.',
  placement: 'bottomLeft',
};

export const ewrcaProfileNotification = {
  message: 'Cannot Process',
  description:
    'This export is too large to process. Please reduce the email count to below 500 third party emails.',
  placement: 'bottomLeft',
};

export const organisationOnlyExportEwrcaNotification = {
  message: 'Cannot Process',
  description: 'Max 10k organisations can be exported at a time.',
  placement: 'bottomLeft',
};

export const exportNotification = largeExport => ({
  message: 'Generating Report',
  description: `We are generating your report and will notify you ${
    largeExport ? 'via email' : ''
  } when it is ready.`,
  placement: 'bottomLeft',
});

export const handleExportNotification = (
  ewrcaTypes,
  largeExport,
  onClose,
  isProfileCategorySelected
) => {
  const descriptions = [];

  if (ewrcaTypes.includes(EwrcaTypes.Ewrca)) {
    descriptions.push(
      isProfileCategorySelected
        ? ewrcaProfileNotification.description
        : ewrcaNotification.description
    );
  }

  if (ewrcaTypes.includes(EwrcaTypes.OrganisationExportEwrca)) {
    descriptions.push(organisationOnlyExportEwrcaNotification.description);
  }

  if (descriptions.length === 0) {
    const toOpen = exportNotification(largeExport);
    notification.open({ ...toOpen, onClose });
    return;
  }

  notification.open({
    message: 'Cannot Process',
    description: (
      <>
        {descriptions.map((desc, index) => (
          <p key={index}>{desc}</p>
        ))}
      </>
    ),
    placement: 'bottomLeft',
    onClose,
  });
};
