import { Fragment, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Input, Button, Link, Checkbox } from 'components/common';
import {
  getOrganisationFilter,
  getOrganisationsToUnfollow,
  getSelectedOrganisations,
} from 'selectors/alerts';
import {
  selectOrganisations,
  setOrganisationFilter,
  setOrganisationsToUnfollow,
} from 'actions/alerts';
import { formatRange } from 'utils/number';
import { useAuth } from 'hooks';

import { setOrgWorkItemFilterGroups, setView } from 'actions/organisation';
import { LogoFlexContainer } from 'components/Organisation/styled';
import SocialMediaLink from 'components/Researcher/Profile/SocialMediaLink';
import { makeHttpsUrl } from 'utils/helpers/externalLink';
import { SelectedPanel } from 'components/common/Table/MultiSelect';
import {
  getAreSomeSelected,
  getAreAllSelected,
  getSelectedCount,
  select,
  isSelected,
} from 'components/common/Table/MultiSelect/helpers';

import { StyledTable, TableActions } from './styled';
import { tablePerPage } from './helpers';
import { RecentResults } from './TablePopover/RecentResults';
import BulkUnfollowModal from './Modal/BulkUnfollow';
import useUnfollow from './useUnfollow';
import useBulkUnfollow from './useBulkUnfollow';

export const OrganisationAlerts = ({
  data,
  getOrganisationsToFollow,
  loading,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const organisationFilter = useSelector(getOrganisationFilter);
  const ref = useRef();
  const { push } = useHistory();

  const filteredData = useMemo(() => {
    if (!data) return [];
    if (!organisationFilter) return data;

    return data.filter(k =>
      `${k.organisationName.toLowerCase()}`.includes(
        organisationFilter.toLowerCase()
      )
    );
  }, [data, organisationFilter]);

  const organisationsToUnfollow = useSelector(getOrganisationsToUnfollow);
  const selected = useSelector(getSelectedOrganisations);
  const someSelected = useMemo(
    () => getAreSomeSelected(data?.length ?? 0, selected),
    [data?.length, selected]
  );
  const allSelected = useMemo(
    () => getAreAllSelected(data?.length ?? 0, selected),
    [data?.length, selected]
  );
  const selectedCount = useMemo(
    () => getSelectedCount(data?.length ?? 0, selected),
    [data?.length, selected]
  );

  const s3Bucket = process.env.REACT_APP_PORTAL_CDN || 'dev-scil-portal-cdn';
  const avatarImg = `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/images/logos/superorganisation/Avatar_40_40.png`;

  const makeImageUrl = r => {
    return `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/${r.logoUrl}_100x100`;
  };

  const addDefaultSrc = ev => {
    ev.target.src = avatarImg;
  };

  const handleUnfollowSuccess = useCallback(() => {
    getOrganisationsToFollow({
      query: { UserId: user.userId, shouldLog: false },
    });
  }, [getOrganisationsToFollow, user.userId]);

  const [, , handleUnfollow, unfollowLoading] = useUnfollow(
    handleUnfollowSuccess
  );

  const unSelectAll = useCallback(() => {
    dispatch(selectOrganisations({ exclude: {}, include: {}, all: false }));
  }, [dispatch]);

  const handleBulkSuccess = useCallback(() => {
    dispatch(setOrganisationsToUnfollow());
    unSelectAll();
    getOrganisationsToFollow({
      query: { UserId: user.userId, shouldLog: false },
    });
  }, [dispatch, getOrganisationsToFollow, unSelectAll, user.userId]);

  const handleBulkCancel = () => {
    dispatch(setOrganisationsToUnfollow());
    unSelectAll();
  };

  const [handleBulkUnfollow, bulkUnfollowLoading] = useBulkUnfollow(
    handleBulkSuccess
  );

  const selectAll = () => {
    const selectedIds = allSelected
      ? {}
      : filteredData.reduce((acc, curr) => {
          acc[curr.organisationId] = true;
          return acc;
        }, {});
    dispatch(
      selectOrganisations({ exclude: {}, include: selectedIds, all: false })
    );
  };

  const handleToggleMember = id => {
    dispatch(selectOrganisations(select(id, selected)));
  };

  const unfollow = organisation => {
    if (isSelected(selected, organisation.organisationId)) {
      handleToggleMember(organisation.organisationId);
    }

    handleUnfollow(organisation);
  };

  const bulkUnfollow = () => {
    if (selectedCount === 1) {
      handleBulkUnfollow(selected);
      return;
    }

    dispatch(
      setOrganisationsToUnfollow({
        members: selected,
        count: selectedCount,
      })
    );
  };

  const organisationConfig = {
    headers: [
      {
        label: (
          <Checkbox
            checked={allSelected && !loading}
            indeterminate={someSelected}
            onClick={selectAll}
          />
        ),
        minWidth: 20,
      },
      { label: 'New', key: 'recentItemsResults', minWidth: 50 },
      { label: ' ', key: '', minWidth: 20 },
      {
        label: 'Organisation',
        key: 'name',
        minWidth: 200,
        maxWidth: 400,
      },
      { label: 'State', key: '', minWidth: 40 },
      { label: 'Country', key: '', minWidth: 50 },
      { label: 'Category', key: 'category', minWidth: 50 },
      { label: 'Size', key: 'SizeRangeLowerBound', minWidth: 25 },
      { label: 'Founded Date', key: 'foundedYear', minWidth: 25 },
      { label: 'LinkedIn', key: '', minWidth: 30 },
      { testId: 'actions', minWidth: 50 },
    ],
    createRow: organisation => (
      <Fragment key={organisation.organisationId}>
        <div>
          <Checkbox
            checked={isSelected(selected, organisation.organisationId)}
            onClick={() => handleToggleMember(organisation.organisationId)}
          />
        </div>

        <div style={{ display: 'flex', marginBottom: '8px' }}>
          <RecentResults
            onClick={last30DaysClicked}
            alert={organisation}
            ref={ref}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <LogoFlexContainer
            style={{
              marginBottom: '4px',
              marginRight: '10px',
              height: '25px',
              width: '25px',
            }}
          >
            {organisation.logoUrl ? (
              <img
                src={makeImageUrl(organisation)}
                onError={addDefaultSrc}
                alt={'Company Logo'}
              />
            ) : (
              <img src={avatarImg} alt={'Company Logo'} />
            )}
          </LogoFlexContainer>
        </div>

        <div>
          <Link to={`/organisation/${organisation.organisationId}`}>
            {organisation.organisationName}
          </Link>
        </div>
        <div>{organisation.state}</div>
        <div>{organisation.country}</div>
        <div>
          <div
            style={{
              maxWidth: 150,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            {organisation.category}
          </div>
        </div>
        <div>
          {(organisation.sizeRangeLowerBound > 0 ||
            organisation.sizeRangeUpperBound > 0) &&
            formatRange(
              organisation.sizeRangeLowerBound,
              organisation.sizeRangeUpperBound
            )}
        </div>
        <div>{organisation.foundedYear}</div>
        <div>
          <SocialMediaLink
            network={{
              type: 'linkedin',
              displayName: 'LinkedIn',
            }}
            title={
              organisation.linkedIn ? 'View on LinkedIn' : 'Search for LinkedIn'
            }
            url={
              organisation.linkedIn
                ? makeHttpsUrl(organisation.linkedIn)
                : `http://www.google.com/search?q=${organisation.name} site:linkedin.com`
            }
            isMuted={!organisation.linkedIn}
            logDescription={{
              entityType: 'Organisation',
              location: `FollowedOrganisation:${organisation.id}`,
            }}
          />
        </div>
        <TableActions className='rightAlign'>
          <Button
            type='primary'
            size='small'
            onClick={() => unfollow(organisation)}
          >
            Unfollow
          </Button>
        </TableActions>
      </Fragment>
    ),
  };

  const last30DaysClicked = org => {
    dispatch(
      setOrgWorkItemFilterGroups(org.organisationId, 'fromRecentItems', true)
    );
    dispatch(setView('workItems'));
    push(`/organisation/${org.organisationId}`);
  };

  const handleOrganisationChange = value => {
    dispatch(setOrganisationFilter(value));
  };
  return (
    <div ref={ref}>
      <Input
        placeholder='Filter Organisations'
        style={{ marginRight: 15, marginTop: 15 }}
        onChange={handleOrganisationChange}
        value={organisationFilter}
        allowClear
      />
      <SelectedPanel
        selectedCount={selectedCount}
        allSelected={allSelected}
        selectAll={selectAll}
        unselectAll={unSelectAll}
        unfollow={bulkUnfollow}
        style={{ margin: '10px 0' }}
      />
      <StyledTable
        config={organisationConfig}
        pageSizeOptions={tablePerPage}
        sortable
        paginate
        data={filteredData}
        loading={loading || unfollowLoading || bulkUnfollowLoading}
        testId='organisation-table'
      />
      {organisationsToUnfollow && (
        <BulkUnfollowModal
          members={organisationsToUnfollow.members}
          totalCount={organisationsToUnfollow.count}
          onUnfollowSuccess={handleBulkSuccess}
          onUnfollowCancel={handleBulkCancel}
        />
      )}
      {/* {organisationToUnfollow && (
        <UnfollowModal
          onUnfollowSuccess={handleUnfollowSuccess}
          onUnfollowCancel={handleUnfollowCancel}
        />
      )} */}
    </div>
  );
};

export default OrganisationAlerts;
