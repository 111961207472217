import React, { useMemo } from 'react';
import { Skeleton } from 'antd';
import Lists from 'components/Researcher/Lists';
import { views } from 'components/Lists/constants';
import { TabbedPanel, UpgradeLicense } from 'components/common';
import { Container as ProfileContainer } from './Profile/styled';
import {
  Column,
  Container,
  GraphColumn,
  LeftColumn,
  Pane,
  Row,
} from './styled';
import { TopCategoriesActions } from './Profile/TopCategories';
import Statistics from './Statistics';
import { ConstrainedTable } from './Work/styled';
import { getConfigs, perPage } from './Work/config';
import Graphs from './Graphs';
import NetworkDiagram from './NetworkDiagram';
import { MeshActions, NetworkActions, WorkItemsActions } from '.';

function CTA() {
  const configs = useMemo(() => getConfigs(true), []);

  const config = {};

  config.funding = {
    icon: 'award',
    text: 'Funding',
    content: (
      <ConstrainedTable
        key='funding-table'
        config={configs.funding}
        data={[]}
        pageSizeOptions={perPage}
        paginate
      />
    ),
  };

  config.posters = {
    icon: 'image',
    text: 'Posters',
    content: (
      <ConstrainedTable
        key='poster-table'
        config={configs.posters}
        data={[]}
        pageSizeOptions={perPage}
        paginate
      />
    ),
  };
  config.publications = {
    icon: 'book',
    text: 'Publications',
    content: (
      <ConstrainedTable
        key='publication-table'
        config={configs.publications}
        data={[]}
        pageSizeOptions={perPage}
        paginate
      />
    ),
  };
  config.clinicalTrials = {
    icon: 'trial',
    text: 'Trials',
    content: (
      <ConstrainedTable
        key='trial-table'
        config={configs.trials}
        data={[]}
        pageSizeOptions={perPage}
        paginate
      />
    ),
  };
  config.network = {
    icon: 'network',
    text: 'Network',
    content: (
      <ConstrainedTable
        key='network-table'
        config={configs.network}
        data={[]}
        pageSizeOptions={perPage}
        paginate
        sortable
      />
    ),
  };

  return (
    <>
      <Container style={{ filter: 'blur(2px)' }}>
        <Row>
          <LeftColumn>
            <Row alignTop>
              <Pane>
                <ProfileContainer>
                  <Skeleton.Avatar size={125} shape='square' />
                  <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
                </ProfileContainer>
              </Pane>
            </Row>
            <Row alignTop>
              <Pane
                title={`Broad Research Categories`}
                headerRight={
                  <TopCategoriesActions
                    loading={false}
                    toggleModal={() => null}
                  />
                }
              >
                <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
              </Pane>
            </Row>
            <Row alignTop>
              <Pane title='List Management'>
                <Lists
                  editableLists={[]}
                  loadingLists={true}
                  type={views.researchers}
                  skipMountLoad
                />
              </Pane>
            </Row>
          </LeftColumn>
          <Column>
            <Statistics researcher={{}} />
            <Pane style={{ flex: 0 }}>
              <TabbedPanel
                horizontal
                config={config}
                onActiveTabChange={() => null}
              />
            </Pane>
            <Row alignTop>
              <GraphColumn>
                <Pane
                  title='Focused Research Areas'
                  headerRight={<MeshActions toggleModal={() => null} />}
                >
                  <Graphs
                    summaryData={{}}
                    count={0}
                    showLegend={false}
                    type='horizontalBar'
                    height='400px'
                    labelLength='20'
                  />
                </Pane>
                <Pane
                  title='Career Activities'
                  headerRight={<WorkItemsActions toggleModal={() => null} />}
                >
                  <Graphs
                    summaryData={{}}
                    showLegend={true}
                    type='bar'
                    labelLength='10'
                  />
                </Pane>
              </GraphColumn>
              <GraphColumn>
                <Pane
                  title='Network Diagram'
                  headerRight={<NetworkActions toggleModal={() => null} />}
                >
                  <NetworkDiagram network={{ nodes: [] }} />
                </Pane>
              </GraphColumn>
            </Row>
          </Column>
        </Row>
      </Container>
      <UpgradeLicense />
    </>
  );
}

CTA.propTypes = {};

export default CTA;
