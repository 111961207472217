const licenseType = {
  legacy: 1,
  academic: 2,
  industry: 4,
  temporaryOld: 16,
};

export const licenseTypeToStringMap = {
  [licenseType.legacy]: 'Essential',
  [licenseType.academic]: 'Academic',
  [licenseType.industry]: 'BioPharma',
  [licenseType.temporaryOld]: 'Essential',
};

export default licenseType;
