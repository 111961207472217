import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/common';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  .unfollow {
    color: ${p => p.theme.unfollow};
    border: 1px solid ${p => p.theme.unfollowBorder};
    :hover,
    :active,
    :focus {
      border-color: ${p => p.theme.unfollow};
    }
  }
`;

function BulkFooter({ onOk, onCancel, loading }) {
  return (
    <Container>
      <Button
        size='middle'
        disabled={loading}
        onClick={onCancel}
        data-testid='unfollow-ok-button'
      >
        Cancel
      </Button>
      <Button
        size='middle'
        disabled={loading}
        onClick={onOk}
        className='unfollow'
        data-testid='unfollow-cancel-button'
      >
        Unfollow selected
      </Button>
    </Container>
  );
}

BulkFooter.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default BulkFooter;
