export const types = {
  SET_VIEW: 'ALERTS_SET_VIEW',
  SET_RESEARCHER_FILTER: 'ALERTS_SET_RESEARCHER_FILTER',
  SET_RESEARCHER_TO_UNFOLLOW: 'ALERTS_SET_RESEARCHER_TO_UNFOLLOW',
  SET_RESEARCHERS_TO_UNFOLLOW: 'ALERTS_SET_RESEARCHERS_TO_UNFOLLOW',
  SET_ALERT_TO_DELETE: 'ALERTS_SET_ALERT_TO_DELETE',
  SET_ALERT_TO_EDIT: 'ALERTS_SET_ALERT_TO_EDIT',
  SET_ORGANISATION_FILTER: 'ALERTS_SET_ORGANISATION_FILTER',
  SET_ORGANISATION_TO_UNFOLLOW: 'ALERTS_SET_ORGANISATION_TO_UNFOLLOW',
  SET_ORGANISATIONS_TO_UNFOLLOW: 'ALERTS_SET_ORGANISATIONS_TO_UNFOLLOW',
  SET_ALERT_TO_CREATE: 'ALERTS_SET_ALERT_TO_CREATE',
  SET_ALERT_FILTER: 'SET_ALERT_FILTER',
  SELECT_RESEARCHERS: 'SELECT_RESEARCHERS',
  SELECT_ORGANISATIONS: 'SELECT_ORGANISATIONS',
};

export const setView = view => ({
  type: types.SET_VIEW,
  payload: { view },
});

export const setAlertFilter = alertsFilter => ({
  type: types.SET_ALERT_FILTER,
  payload: { alertsFilter },
});

export const setResearcherFilter = researcherFilter => ({
  type: types.SET_RESEARCHER_FILTER,
  payload: { researcherFilter },
});

export const setResearcherToUnfollow = researcherToUnfollow => ({
  type: types.SET_RESEARCHER_TO_UNFOLLOW,
  payload: { researcherToUnfollow },
});

export const setOrganisationFilter = organisationFilter => ({
  type: types.SET_ORGANISATION_FILTER,
  payload: { organisationFilter },
});

export const setOrganisationToUnfollow = organisationToUnfollow => ({
  type: types.SET_ORGANISATION_TO_UNFOLLOW,
  payload: { organisationToUnfollow },
});

export const setAlertToDelete = alertToDelete => ({
  type: types.SET_ALERT_TO_DELETE,
  payload: { alertToDelete },
});

export const setAlertToEdit = alertToEdit => ({
  type: types.SET_ALERT_TO_EDIT,
  payload: { alertToEdit },
});

export const setAlertToCreate = alertToCreate => ({
  type: types.SET_ALERT_TO_CREATE,
  payload: { alertToCreate },
});

export const selectResearchers = selected => ({
  type: types.SELECT_RESEARCHERS,
  payload: { selected },
});

export const setResearchersToUnfollow = researchersToUnfollow => ({
  type: types.SET_RESEARCHERS_TO_UNFOLLOW,
  payload: { researchersToUnfollow },
});

export const selectOrganisations = selected => ({
  type: types.SELECT_ORGANISATIONS,
  payload: { selected },
});

export const setOrganisationsToUnfollow = organisationsToUnfollow => ({
  type: types.SET_ORGANISATIONS_TO_UNFOLLOW,
  payload: { organisationsToUnfollow },
});
