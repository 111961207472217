import { Skeleton } from 'antd';
import {
  Pane,
  Container,
  Row,
  Column,
  StatsColumn,
  RelatedColumn,
} from './styled';
import { Statistic, UpgradeLicense } from 'components/common';

function CTA() {
  return (
    <>
      <Container style={{ filter: 'blur(2px)' }}>
        <Row>
          <Column>
            <Pane title='Trial Details' id='details'>
              <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
            </Pane>
          </Column>
          <StatsColumn>
            <Statistic title='Publications' icon='book' value='-' />
            <Statistic title='Grants' icon='award' value='-' />
            <Statistic title='Keywords' icon='keywords' value='-' />
            <Statistic title='Focused Research Areas' icon='mesh' value='-' />
          </StatsColumn>
          <RelatedColumn>
            <Pane title='Related Information' id='related'>
              <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
            </Pane>
          </RelatedColumn>
        </Row>
        <Row>
          <Pane title='Study Description' id='description'>
            <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
          </Pane>
          <Pane title='Study Design' id='design'>
            <Skeleton paragraph={{ rows: 6 }} style={{ marginTop: 5 }} />
          </Pane>
        </Row>
        <Row>
          <Pane title='Keywords' id='keywords'>
            <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: 5 }} />
          </Pane>
          <Pane title='Focused Research Areas' id='mesh'>
            <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: 5 }} />
          </Pane>
        </Row>
        <Pane title='Arms and Interventions' id='arms'>
          <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: 5 }} />
        </Pane>
        <Pane title='Primary Outcome Measures' id='primary'>
          <Skeleton paragraph={{ rows: 3 }} style={{ marginTop: 5 }} />
        </Pane>
        <Pane title='Secondary Outcome Measures' id='secondary'>
          <Skeleton paragraph={{ rows: 3 }} style={{ marginTop: 5 }} />
        </Pane>
        <Pane title='Eligibility Criteria' id='eligibility'>
          <Skeleton paragraph={{ rows: 3 }} style={{ marginTop: 5 }} />
        </Pane>
        <Pane title='Contacts and Locations' id='locations'>
          <Skeleton paragraph={{ rows: 3 }} style={{ marginTop: 5 }} />
        </Pane>
      </Container>
      <UpgradeLicense />
    </>
  );
}

export default CTA;
