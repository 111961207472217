import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePost } from 'hooks';
import { bulkUnfollowOrganisation, bulkUnfollowResearcher } from 'services/api';
import { getAlertsView } from 'selectors/alerts';
import { views } from './constants';

function useBulkUnfollow(onUnfollowSuccess) {
  const view = useSelector(getAlertsView);
  const isResearcher = view === views.followedResearchers;

  const [{ res: success, loading, error }, postUnfollow] = usePost({
    url: isResearcher ? bulkUnfollowResearcher : bulkUnfollowOrganisation,
  });

  useEffect(() => {
    if (success) {
      onUnfollowSuccess();
    }
  }, [error?.status, onUnfollowSuccess, success]);

  const handleUnfollow = members => {
    postUnfollow({
      applyToAll: members.all,
      ids: Object.keys(members.include).map(x => +x),
      excludeIds: Object.keys(members.exclude).map(x => +x),
    });
  };

  return [handleUnfollow, loading, isResearcher];
}

export default useBulkUnfollow;
